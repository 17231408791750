import React from "react"
import { Link } from "gatsby"

import Layout from "../../layout/layout"
import Button from "../../components/button"
import CustomCourseContent from "./customContent/customCourseContent"

const QulificationInfo = ({ pageContext }) => {
  return (
    <div className="qualification-info-container">
      <div className="qualification-info-item">
        <h6 style={{ marginBottom: "22px" }}>NZQA info</h6>
        <div className="qualification-info-item-title">Programme number</div>
        <div className="p-md">{pageContext.programmeNumber}</div>
      </div>
      <div className="qualification-info-item">
        <div className="qualification-info-item-title">Type</div>
        <div className="p-md">{pageContext.type}</div>
      </div>
      <div className="qualification-info-item">
        <div className="qualification-info-item-title">Level</div>
        <div className="p-md">{pageContext.level}</div>
      </div>
      <div className="qualification-info-item">
        <div className="qualification-info-item-title">Total credits</div>
        <div className="p-md">{pageContext.totalCredits}</div>
      </div>
      <div className="qualification-info-item">
        <div className="qualification-info-item-title">Total learning hours</div>
        <div className="p-md">{pageContext.totalLearningHours}</div>
      </div>
      <div className="qualification-info-item-noborder">
        <div className="qualification-info-item-title">{pageContext.price}</div>
      </div>
    </div>
  )
}

const Qualifiction = ({ pageContext }) => {
  console.log(pageContext)
  return (
    <Layout
      title={pageContext !== undefined ? pageContext.title : ""}
      description={pageContext !== undefined ? pageContext.metaContent : ""}
    >
      <div
        className="layout-wrapper subject-page-heading-wrapper"
        style={{
          backgroundImage: `url(${pageContext.subjectBackgroundImage})`,
        }}
      >
        <h3 className="subject-page-heading layout-container">
          {pageContext.title}
        </h3>
      </div>
      <div className="layout-container qualification-content-container">
        <div
          className={
            pageContext.hideQualificationMenu ? "" : `qualification-content`
          }
        >
          <div className="qualification-crumbs">
            <Link to={`/subject/${pageContext.subjectSlug}`}>
              {pageContext.subjectLabel}
            </Link>
            {` / ${pageContext.name}`}
          </div>
          {pageContext.overview !== undefined ? (
            <div className="qualification-content-part">
              <h6>Overview</h6>
              <p>{pageContext.overview}</p>
            </div>
          ) : null}

          {pageContext.conditionalHeading1 !== undefined ? (
            <p>{pageContext.conditionalHeading1}</p>
          ) : null}
          {pageContext.conditionalDataMap1 !== undefined ? (
            <ul>
              {pageContext.conditionalDataMap1.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          ) : null}

          {pageContext.conditionalHeading2 !== undefined ? (
            <p>{pageContext.conditionalHeading2}</p>
          ) : null}
          {pageContext.conditionalDataMap2 !== undefined ? (
            <ul>
              {pageContext.conditionalDataMap2.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          ) : null}
          {pageContext.conditionalItalic2 !== undefined ? (
            <p style={{ fontStyle: "italic", marginBottom: 35 + "px" }}>
              {pageContext.conditionalItalic2}
            </p>
          ) : null}
          {pageContext.hideQualificationMenu ? null : (
            <div className="only-mobile">
              <QulificationInfo pageContext={pageContext}></QulificationInfo>
            </div>
          )}
          <div className="qualification-content-part">
            {pageContext.additionalInfo ? (
              <h6>Additional information</h6>
            ) : null}
            <p>{pageContext.additionalInfo}</p>
            {pageContext.conditionalAdditionalInformationDataMap2 !==
            undefined ? (
              <ul>
                {pageContext.conditionalAdditionalInformationDataMap2.map(
                  (i) => (
                    <li>{i}</li>
                  )
                )}
              </ul>
            ) : null}
          </div>
          {pageContext.customCourseContent !== undefined ? (
            <CustomCourseContent content={pageContext.customCourseContent} />
          ) : null}
          
          {pageContext.hideContactBtn ? null : (
            <div>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
          )}
        </div>
        {pageContext.hideQualificationMenu ? null : (
          <div className="hide-mobile">
            <QulificationInfo pageContext={pageContext}></QulificationInfo>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Qualifiction
